import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import Layout from "../layouts/ja";
import SEO from "../components/seo";
import Img_banner from "../images/banner-promotion.png";
import { Tabs } from "antd";
import $ from "jquery";

const { TabPane } = Tabs;

const PartnerPage = (props) => {
  const [state, setState] = useState({
    benefitDataSelected: false,
  });

  useEffect(() => {
    init();
  }, [state.benefitDataSelected]);

  const init = () => {
    $(".cta-expand-ja").off("click");
    expandData();
  };

  const onChangeTab = () => {
    setState(!state.benefitDataSelected);
  };

  const expandData = () => {
    $(".cta-expand-ja").on("click", function (e) {
      var target = $(this).data("target");

      $(target).slideToggle(400);
      $(this).closest(".copy-ja").find(".content-ja").toggleClass("active");

      if (!$(this).hasClass("active")) {
        $(this).addClass("active").html("Show Less");
      } else {
        $(this).removeClass("active").html("Show More");
      }
    });
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        @media (max-width: 800px) {
          .benefit__special{
            display: block !important
          }
        }
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
          margin: 0px auto
        }
        .benefit__special .table--benefit {
          display: block
        }
        .benefit__tab {
          font-weight: normal
        }
        .ant-tabs-tab {
          margin-right: -15px;
        }
        @media (max-width:480px){
          .ant-tabs-tab {
            margin-right: -3px;
          }
        }
      `,
        }}></style>

      <Layout location={props.location}>
        <SEO title="提携パートナー＆特典内容 " />

        <div id="contentWrap">
          <div id="index_content" className="admin-setting">
            <div className="benefit__special partner_content">
              <div className="container container--600">
                <h1 className="text--center">提携パートナー＆特典内容</h1>
                <p className="text--center">
                  提携パートナーの最新プロモーションを利用して充実したライフスタイルを
                </p>
              </div>
              <div className="container container--1000">
                <Tabs
                  className="partner__tab"
                  defaultActiveKey="benefit-all"
                  size="large"
                  onChange={onChangeTab}>
                  <TabPane tab="阿雅娜集团" key="benefit-all">
                    <div className="table--benefit">
                      <div className="list-item">
                        <div className="row">
                          <div className="col col-5 col-sm-12">
                            <div className="img">
                              <img
                                alt=""
                                src="https://resource.ayana.com/live/rewards/page/Webp.net-resizeimage_(42).jpg"
                              />
                            </div>
                          </div>
                          <div className="col col-7 col-sm-12">
                            <div className="copy copy-ja">
                              <h3 className="title">カワノグループ</h3>
                              <div className="content content-ja">
                                <p className="desc">
                                  アヤナリワーズのポイントで、カワノレストランのお食事券＋カワノグループレストランでのお食事が10%割引き。インドネシアの上質レストランを獲得ポイントでお楽しみください。
                                </p>
                              </div>
                              <div
                                className="container-expandable hide"
                                id="collapse-1">
                                <span>ご利用規約</span>
                                <br />
                                1. メンバー割引き
                                <br />
                                ―下記のカワノグループレストランにてメンバー特典10%割引をご用意しています：
                                <br />
                                <ul className="dots">
                                  <li>
                                    KaDo（アヤナ ミッドプラザ ジャカルタ）
                                  </li>
                                  <li>
                                    Honzen（アヤナ ミッドプラザ ジャカルタ）
                                  </li>
                                  <li>Honzen (アヤナ バリ)</li>
                                  <li>Honzen（アヤナコモドリゾート）</li>
                                  <li>Sakana（ミッドプラザ）</li>
                                  <li>Sakana（メガクニンガン）</li>
                                  <li>Sakana（デロニクス カラワン）</li>
                                  <li>Tontoki（ミッドプラザ）</li>
                                  <li>Tontoki（メガクニンガン）</li>
                                  <li>The Grill（ミッドプラザ）</li>
                                  <li>Udonku （ミッドプラザ）</li>
                                  <li>Aro Aroy（ミッドプラザ）</li>
                                  <li>Bistronomy (ラブアンバジョー)</li>
                                  <li>Riverside Restaurant</li>
                                </ul>
                                -
                                ご利用にはayanarewards.comへログインし、メンバーシップのスクリーンショットをお送りください。
                                <br />-
                                ご利用料金の額に関わりなく割引をご提供いたします。
                                <Link to="/ja/reward">
                                  <span style={{ color: "#0000cd" }}>
                                    <span>本ページ</span>
                                  </span>
                                </Link>
                                <br />
                                - アルコール飲料は割引対象となりません。
                                <br />
                                -
                                本割引は他の割引/特典プロモーションとの併用はお受けしておりません
                                <br />
                                <br />
                                2. ポイント還元
                                <br />
                                -
                                本ページよりIDR300,000相当のカワノグループレストランお食事券を、1,250ポイントにて交換いただけます。
                                <br />
                                -
                                ご利用にはEクーポンを印刷してレストランまでご提示ください。
                                <br />
                                -
                                お食事のお支払いには、現金/クレジットカード精算とギフト券を組み合わせることも可能です。
                                <br />
                              </div>
                              <a
                                className="cta-expand cta-expand-ja btn-toggle"
                                data-target="#collapse-1">
                                詳細を見る
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="row">
                          <div className="col col-5 col-sm-12">
                            <div className="img">
                              <img
                                alt=""
                                src="https://resource.ayana.com/live/rewards/page/Webp.net-resizeimage_(43).jpg"
                              />
                            </div>
                          </div>
                          <div className="col col-7 col-sm-12">
                            <div className="copy copy-ja">
                              <h3 className="title">
                                リバーサイドゴルフクラブ
                              </h3>
                              <div className="content content-ja">
                                <p className="desc">
                                  <span style={{ fontSize: "11.5pt" }}>位</span>
                                  <span style={{ fontSize: "11.5pt" }}>
                                    置: Bukit Golf Riverside Cimanggis, West
                                    Java
                                  </span>
                                  <br />
                                  ポイント交換で平日＆週末のゴルフをお楽しみください。楽しく貯めて、楽しくプレイ
                                </p>
                              </div>
                              <div
                                className="container-expandable hide"
                                id="collapse-2">
                                <span>Terms &amp; Condition</span>
                                <br />-
                                メンバーはポイント交換でリバーサイドゴルフクラブのゴルフが無料に。平日のラウンドはお1人様2,500ポイント、週末のラウンドはお1人様7,700ポイント。
                                <Link to="/ja/reward">
                                  <span style={{ color: "#0000cd" }}>
                                    <span>本ページ</span>
                                  </span>
                                </Link>
                                <br />
                                -
                                還元内容にはグリーンフィー、ゴルフサービス（カートとキャディー）、税金、保険が含まれています。
                                <br />
                                -
                                ご利用にはあらかじめEメールinfo@ayanarewards.comより、ご予約をお願いいたします。
                                <br />
                                -
                                土曜日、日曜日、祝祭日のご予約は2週間前までにお願いいたします。
                                <br />
                                - メンバーは受付にてIDカードをご提示ください。
                                <br />
                                -
                                リバーサイドレストランにてメンバー特典の10%割引をご用意しております。
                                <br />
                                -
                                すべてのご予約は返金/キャンセルはお受けしておりません。ポイントは交換時に還元ポリシーに準じて差し引かれます。
                                <br />
                              </div>
                              <a
                                className="cta-expand cta-expand-ja btn-toggle"
                                data-target="#collapse-2">
                                詳細を見る
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="row">
                          <div className="col col-5 col-sm-12">
                            <div className="img">
                              <img
                                alt=""
                                src="https://resource.ayana.com/live/rewards/page/Webp.net-resizeimage_(41).jpg"
                              />
                            </div>
                          </div>
                          <div className="col col-7 col-sm-12">
                            <div className="copy copy-ja">
                              <h3 className="title">フラワースタジオ</h3>
                              <div className="content content-ja">
                                <p className="desc">
                                  <span style={{ fontSize: "11.5pt" }}>位</span>
                                  <span style={{ fontSize: "11.5pt" }}>
                                    置:&nbsp;Midplaza Building 1 Lvl Basement,
                                    Jakarta
                                  </span>
                                  <br />
                                  アヤナリワーズのポイントでフラワースタジオのクーポン＋カスタムフラワーアレンジメントが10%割引きに。ポイント交換でいつもの暮らしに美しい花を添えましょう。
                                </p>
                              </div>
                              <div
                                className="container-expandable hide"
                                id="collapse-3">
                                <span>Terms &amp; Condition</span>
                                <br />
                                1. メンバー割引き
                                <ul className="dots">
                                  <li>
                                    フラワースタジオにて全てのフラワーアレンジを10%割引でご提供
                                  </li>
                                  <li>
                                    ご利用にはayanarewards.comへログインし、メンバーシップのスクリーンショットをお送りください。
                                  </li>
                                  <li>
                                    ご利用料金の額に関わりなく割引きをご提供いたします。
                                  </li>
                                  <li>
                                    他の割引/特典プロモーションとの併用はお受けしておりません。
                                  </li>
                                </ul>
                                <br />
                                2. ポイント還元
                                <ul className="dots">
                                  <li>
                                    本ページより、IDR300,000、IDR500,000、IDR1,000,000相当のフラワースタジオギフト券をポイントと交換できます。
                                    <Link to="/ja/reward">
                                      <span style={{ color: "#0000cd" }}>
                                        本ページ
                                      </span>
                                    </Link>
                                  </li>
                                  <li>
                                    ご利用には印刷したEクーポンをフラワースタジオまでご提示ください。
                                  </li>
                                  <li>
                                    ポイント交換の前日までにあらかじめフラワーアレンジの事前注文をお勧めいたしますが、当日フラワースタジオにてお花の在庫より直接ご注文いただく事も可能です。
                                  </li>
                                  <li>
                                    商品のお支払いには、現金/クレジットカード精算とギフト券を組み合わせることも可能です。
                                  </li>
                                </ul>
                              </div>
                              <a
                                className="cta-expand cta-expand-ja btn-toggle"
                                data-target="#collapse-3">
                                詳細を見る
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>

                  {/* <TabPane tab="航空会社マイレージ" key="benefit-ayana">
                    <div className="table--benefit">
                      <div className="list-item">
                        <div className="row">
                          <div className="col col-5 col-sm-12">
                            <div className="img">
                              <img
                                alt=""
                                src="https://resource.ayana.com/live/rewards/page/KrisFlyer.jpg"
                              />
                            </div>
                          </div>
                          <div className="col col-7 col-sm-12">
                            <div className="copy copy-ja">
                              <h3 className="title">クリスフライヤーマイル</h3>
                              <div className="content content-ja">
                                <p className="desc">
                                  アヤナリワード2,000ポイントでクリスフライヤー1,000マイルと交換
                                  ご滞在を楽しみながら、マイルを獲得しましょう
                                </p>
                              </div>
                              <div
                                className="container-expandable hide"
                                id="collapse-5">
                                <span>Terms &amp; Condition</span>
                                <ul className="dots">
                                  <li>
                                    アヤナリワード2,000ポイントでクリスフライヤー1,000マイルと交換が可能（逆の交換は不可）
                                  </li>
                                  <li>
                                    交換方法はこのページをご覧ください。
                                    <Link to="/ja/reward">
                                      <span style={{ color: "#0000cd" }}>
                                        本ページ
                                      </span>
                                    </Link>
                                  </li>
                                  <li>
                                    メンバーはクリスフライヤーメンバーシップ番号、ご氏名（ファーストネーム/ラストネーム）を、アヤナリワーズまでEメール：info@ayanarewards.comをお送りください。
                                  </li>
                                  <li>
                                    ポイント変換完了まで最大7日の処理日数がかかりますことご了承ください。
                                  </li>
                                </ul>
                              </div>
                              <a
                                className="cta-expand cta-expand-ja btn-toggle"
                                data-target="#collapse-5">
                                詳細を見る
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="list-item">
                        <div className="row">
                          <div className="col col-5 col-sm-12">
                            <div className="img">
                              <img
                                alt=""
                                src="https://resource.ayana.com/live/rewards/page/GARUDA.jpg"
                              />
                            </div>
                          </div>
                          <div className="col col-7 col-sm-12">
                            <div className="copy copy-ja">
                              <h3 className="title">ガルーダマイル</h3>
                              <div className="content content-ja">
                                <p className="desc">
                                  アヤナリワーズ2,000ポイントでガルーダマイルズ1,000マイルと交換が可能
                                  楽しみながらポイントを貯めて、好きな旅行に出かけましょう
                                </p>
                              </div>
                              <div
                                className="container-expandable hide"
                                id="collapse-6">
                                <span>Terms &amp; Condition</span>
                                <ul className="dots">
                                  <li>
                                    アヤナリワード2,000ポイントでガルーダマイルズ1,000マイルと交換が可能（逆の交換は不可）
                                  </li>
                                  <li>
                                    交換方法はこのページをご覧ください。
                                    <Link to="/ja/reward">
                                      <span style={{ color: "#0000cd" }}>
                                        本ページ
                                      </span>
                                    </Link>
                                  </li>
                                  <li>
                                    メンバーはガルーダマイルズのメンバーシップ番号、ご氏名（ファーストネーム/ラストネーム）を、アヤナリワーズまでEメール：info@ayanarewards.comよりお送りください。
                                  </li>
                                  <li>
                                    ポイント変換完了まで最大7日の処理日数がかかりますことご了承ください。
                                  </li>
                                </ul>
                              </div>
                              <a
                                className="cta-expand cta-expand-ja btn-toggle"
                                data-target="#collapse-6">
                                詳細を見る
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane> */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PartnerPage;
